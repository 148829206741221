$(function () {
  /*
   * Edit Exam Page
   * @Ezzdin
   *
   * */

  // Make the select in edit exam apply on all row
  $(".edit-exam-page .exam .exam-item input[type='checkbox']").on("change", function () {
    if ($(this).is(":checked")) {
      $(this.parentNode.parentNode.parentNode).addClass("selected");
    } else {
      $(this.parentNode.parentNode.parentNode).removeClass("selected");
    }
  });

  // Allow Drag & Drop
  $(".edit-exam-page .exam").sortable({
    handle: ".exam-drag",
    update: numberingTheOrderingQuestions.bind(this, ".edit-exam-page .edit-exam-content ul li", ".exam-num"),
  });

  $(".edit-exam-page .exam").disableSelection();

  // The select in import exam questions in step 1
  $(".import-exam-question-step-1 .questions li").on("click", function () {
    $(".import-exam-question-step-1 .questions li").removeClass("selected");
    $(this).toggleClass("selected");
    if ($(".import-exam-question-step-1 .questions li.selected").length) {
      $(".import-exam-question-step-1 .question-next").removeAttr("disabled");
    } else {
      $(".import-exam-question-step-1 .question-next").attr("disabled", "disabled");
    }
  });

  // The select in import exam questions in step 2
  $(".import-exam-question-step-2 .questions li").on("click", function () {
    if (!$(this).children(".quiz-checkbox").children("input").is(":checked")) {
      $(this).addClass("selected");
      $(this).children(".quiz-checkbox").children("input").prop("checked", true);
    } else {
      $(this).removeClass("selected");
      $(this).children(".quiz-checkbox").children("input").prop("checked", false);
    }

    var len = $(".import-exam-question-step-2 .questions li.selected").length;
    $(".questions-heading > span span").text(len);
    if (len) {
      $(".import-exam-question-step-2 .import-questions").removeAttr("disabled");
    } else {
      $(".import-exam-question-step-2 .import-questions").attr("disabled", "disabled");
    }
  });

  // Numbering the questions
  numberingTheOrderingQuestions(".edit-exam-page .edit-exam-content ul li", ".exam-num");

  // Deplicate a question functionality
  $(".edit-exam-page .exam-item .copy").on("click", function () {
    const item = $(this).parent().parent();
    item.after(
      item.clone([true]).each(function (i) {
        const idNum = parseInt(item.siblings().length) + 2;
        $(this)
          .find(".quiz-checkbox input")
          .attr("id", "exams" + idNum);
        $(this)
          .find(".quiz-checkbox label")
          .attr("for", "exams" + idNum);
      })
    );
    numberingTheOrderingQuestions(".edit-exam-page .edit-exam-content ul li", ".exam-num");
    numbersOfEditExamHeaderPage();
  });

  // Make the numbers in the header of the page
  function numbersOfEditExamHeaderPage() {
    const items = $(".edit-exam-page .exam-item");
    $(".edit-exam-page .exam-details .questions-num").text(items.length);
    let sum = 0;
    items.each(function (index, element) {
      const num = parseFloat($(element).find(".exam-pts span").text(), 10);
      if (num) sum += num;
    });
    $(".edit-exam-page .exam-details .points-num").text(sum);
  }
  numbersOfEditExamHeaderPage();
});

/*
 * Create Question Modal
 * @Ezzdin
 *
 * */

$(function () {
  let currentOffset = 0;

  //open & close create question
  $(".open-create-question").on("click", function () {
    $("body").addClass("quiz-overflow-hidden");
    $("#create-question").fadeIn();
    $(this).focus();
    currentOffset = $(document).scrollTop();
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      1
    );
  });

  $("#close-create-question").on("click", function () {
    let valid = true;
    const targetClass = $(".create-question main div.selected").attr("class").split(" ")[0];

    if ($(`.create-question .${targetClass} li.error`).length) {
      valid = false;
    }

    if (
      $(`.create-question .${targetClass} .error-selected-message`).length &&
      $(`.create-question .${targetClass} .error-selected-message`).css("display") !== "none"
    ) {
      valid = false;
    }

    if (
      $(`.create-question .${targetClass} header input[type='number'].error`).length &&
      $(`.create-question .${targetClass} header .questions-points-criteria select`).val() === "Points"
    ) {
      valid = false;
    }

    if ($(".create-question .text-passage-editor").css("border-color") === "rgb(255, 0, 0)") {
      valid = false;
    }

    if (valid) {
      $("html, body").animate(
        {
          scrollTop: currentOffset,
        },
        1
      );

      $("body").removeClass("quiz-overflow-hidden");
      $("#create-question").fadeOut();
    }
  });

  // add selected class to the ul of the selected items
  $(".create-question main .question-choices li input[type='checkbox']").on("change", addSelectedClassCheckbox);

  $(".create-question main .question-choices li input[type='radio']").on("change", addSelectedClassRadio);

  function addSelectedClassCheckbox() {
    if ($(this).is(":checked")) {
      $(this).parent().parent().parent().addClass("selected");
    } else {
      $(this).parent().parent().parent().removeClass("selected");
    }
  }

  function addSelectedClassRadio() {
    $(this).parents("ul").children("li").removeClass("selected");
    if ($(this).is(":checked")) {
      $(this).parent().parent().parent().addClass("selected");
    } else {
      $(this).parent().parent().parent().removeClass("selected");
    }
  }

  // Add textarea events
  const questionChoicesTextarea = $(".create-question main textarea");

  function textareaInput() {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";

    if ($(this).val().trim() !== "") {
      $(this).parent().removeClass("error");
    }
    const arr = $(this).val().split("\n");
    if (arr.length > 1) {
      // $(this).val(arr[0]);
      let index = $(this).parent().parent().children("li").index($(this).parent());
      arr.forEach((element) => {
        if (!$(this).parent().parent().children("li")[index])
          $(`.create-question .questions-settings > div.selected footer button`).trigger("click");
        $($(this).parent().parent().children("li")[index++])
          .children("textarea")
          .val(element)
          .attr("data-value", element)
          .focus();
      });
    }
  }

  function textareaFocus() {
    $(this).val($(this).attr("data-value"));
    $(this).removeAttr("data-value");
    $(this).attr("style", `height: ${this.scrollHeight}px;overflow-y:hidden;`);
    $(this).trigger("input");
  }

  function textareaBlur() {
    $(this).attr("style", `white-space: nowrap;overflow:hidden;`);
    const val = $(this).val();
    $(this).attr("data-value", val);
    if ($(this)[0].scrollWidth > $(this).innerWidth() + 1 && val !== "") {
      let MAX = $(this).innerWidth() * 0.2;
      do {
        $(this).val($(this).val().substring(0, MAX) + "...");
        MAX--;
      } while ($(this)[0].scrollWidth > $(this).innerWidth() + 1 && MAX > 0);
    }
  }

  questionChoicesTextarea.on("input", textareaInput);
  questionChoicesTextarea.on("blur", textareaBlur);
  questionChoicesTextarea.on("focus", textareaFocus);

  questionChoicesTextarea.on("keypress", addNewTextArea);
  $(".create-question .questions-settings > div footer button").on("click", addNewTextArea);

  function addNewTextArea(e) {
    if (e.which == 13 || e.keyCode == 13 || e.type == "click") {
      e.preventDefault();
      if ($(this).parent("li").next().find("textarea").length && e.which == 13) {
        $(this).parent("li").next().find("textarea").focus().select();
      } else {
        const targetClass = $(this).parents(".questions-settings > div").attr("class").split(" ")[0];

        /*
         * Multi Dropdown question
         * This kind of questions have a diffrenent type of events and how to handle it
         * So it's in a part in it's own :)
         *
         */
        if (targetClass === "multi-drop-down") {
          const id = $(".create-question .multi-drop-down").find("header ~ div.selected").attr("id");
          if ($(`#${id} ul li`).length >= 99) {
            $(`#${id} footer button`).css("visibility", "hidden");
          }
          if ($(`#${id} ul li`).length === 100) {
            return;
          }
          const num = parseInt(getLastItem($(`#${id} ul li:last-child label`).attr("for").split("_")), 10) + 1;
          const element = `
            <li>
              <label for="choice_correct_${id}_${num}"> </label>
              <div class="custom-checkbox">
                <div class="form-check">
                  <input class="form-control" type="radio" id="choice_correct_${id}_${num}" name="choice_correct_${id}">
                  <label for="choice_correct_${id}_${num}"></label>
                </div>
              </div>
              <textarea rows="1" name="choice_name_${id}_${num}" id="choice_name_${id}_${num}" placeholder="Type a Choice here" maxlength="50"></textarea>
              <i class="far fa-trash-alt delete-question"></i>
            </li>
          `;
          $(`#${id} ul`).append(element);
          const el = $(getLastItem($(`#${id} ul li textarea:last-of-type`)));
          el.focus();
          el.on("input", textareaInput);
          el.on("focus", textareaFocus);
          el.on("blur", textareaBlur);
          el.siblings(".delete-question").on("click", deleteQuestions);
          el.on("keypress", addNewTextArea);
          el.siblings(".custom-checkbox")
            .children(".form-check")
            .children("input[type='radio']")
            .on("change", addSelectedClassRadio);
          $("#" + id + " .delete-question").fadeIn();
          $("#" + id + " li .custom-checkbox input[type='radio']").on(
            "change",
            removeErrorMessageWhenSelectingOptionInMultiDropdown
          );
          return;
        }

        const $targetElement = $(`.create-question main .${targetClass}`);
        const len = $(`.create-question main .${targetClass} ul li`).length;

        if (len >= 99) $(`.create-question main .${targetClass} footer button`).css("visibility", "hidden");
        if (len === 100) return;

        const num = len + 1;

        let element = null;

        if (targetClass === "multi-choices") {
          element = `
            <li><i class="fas fa-grip-vertical choice-drag ui-sortable-handle"></i>
              <div class="custom-checkbox">
                <div class="form-check">
                  <input class="form-control" type="checkbox" id="choice_correct_${num}" name="choice_correct">
                  <label for="choice_correct_${num}"></label>
                </div>
              </div>
              <textarea rows="1" name="choice_name_${num}" id="choice_name_${num}" placeholder="Type a Choice here"></textarea>
              <input type="number" name="choice_grade_${num}" min="0.5" step="0.5" value="0">
              <i class="far fa-trash-alt delete-question"></i>
            </li>
          `;
        } else if (targetClass === "multi-true-false") {
          element = `
            <li>
              <i class="fas fa-grip-vertical choice-drag ui-sortable-handle"></i>
              <span class="question-status">
                <i class="fas fa-question-circle"></i>
              </span>
              <textarea rows="1" name="true_choice_title" id="choice_title_${num}" placeholder="Add Choice"></textarea>
              <input type="number" name="choice_grade_${num}" min="0" step="0.5" value="0">
              <i class="far fa-trash-alt delete-question"></i>
            </li>
          `;
        } else if (targetClass === "ordering") {
          element = `
            <li>
              <i class="fas fa-grip-vertical choice-drag ui-sortable-handle"></i>
              <span class="choice-number"></span>
              <textarea rows="1" name="true_choice_title" id="choice_title_${num}" placeholder="Add Choice"></textarea>
              <input type="number" name="choice_grade_${num}" min="0" step="0.5" value="0">
              <i class="far fa-trash-alt delete-question"></i>
            </li>
          `;
        } else if (targetClass === "single-choice") {
          element = `
            <li>
              <i class="fas fa-grip-vertical choice-drag ui-sortable-handle"></i>
              <label for="single_choice_correct_${num}"></label>
              <div class="custom-checkbox">
                <div class="form-check">
                  <input class="form-control" type="radio" id="single_choice_correct_${num}" name="single_choice_correct">
                  <label for="single_choice_correct_${num}"></label>
                </div>
              </div>
              <textarea rows="1" name="sigle_choice_name_${num}" id="sigle_choice_name_${num}" placeholder="Type a Choice here"></textarea><i class="far fa-trash-alt delete-question"></i>
            </li>
          `;
        } else if (targetClass === "drop-down") {
          element = `
            <li>
              <i class="fas fa-grip-vertical choice-drag ui-sortable-handle"></i>
              <label for="dropdown_correct_${num}"></label>
              <div class="custom-checkbox">
                <div class="form-check">
                  <input class="form-control" type="radio" id="dropdown_correct_${num}" name="dropdown_correct">
                  <label for="dropdown_correct_${num}"></label>
                </div>
              </div>
              <textarea rows="1" name="dropdown_name_${num}" id="dropdown_name_${num}" placeholder="Type a Choice here"></textarea><i class="far fa-trash-alt delete-question"></i>
            </li>
          `;
        }
        $(`.create-question main .${targetClass} ul`).append(element);
        const el = $targetElement.find("textarea").last();
        el.focus();
        el.on("input", textareaInput);
        el.on("focus", textareaFocus);
        el.on("blur", textareaBlur);
        el.siblings(".delete-question").on("click", deleteQuestions);
        el.on("keypress", addNewTextArea);
        el.siblings(".custom-checkbox")
          .children(".form-check")
          .children("input[type='checkbox']")
          .on("change", addSelectedClassCheckbox);
        el.siblings(".custom-checkbox")
          .children(".form-check")
          .children("input[type='radio']")
          .on("change", addSelectedClassRadio);
        el.find("input[type='number']").on("blur", validatePartialPointsInput);
        el.parents(".questions-settings > .selected")
          .find(".questions-points-criteria select")
          .triggerHandler("change");
        el.parents(".questions-settings > .selected").find(".delete-question").fadeIn();
        el.parents(".questions-settings > .selected").find("footer .shuffle").fadeIn();
        el.siblings(".question-status").on("click", multiTrueFalseStatus);
        $targetElement.find(".shuffle").fadeIn();
        numberingTheOrderingQuestions(".create-question .ordering ul li", ".choice-number");
      }
    }
  }

  // Delete question functionality
  $(".create-question main ul li .delete-question").on("click", deleteQuestions);

  function deleteQuestions() {
    const targetClass = $(".create-question main div.selected").attr("class").split(" ")[0];
    $(this).parents(`.${targetClass}`).find("footer > button").css("visibility", "visible");
    const $siblings = $(this).parent("li").siblings("li");
    $(this).parent("li").remove();

    if ((targetClass === "ordering") & ($siblings.length === 2)) {
      $siblings.find(".delete-question").hide();
    } else if (targetClass === "multi-drop-down" && $(".multi-drop-down div.selected li").length === 3) {
      $siblings.find(".delete-question").hide();
    } else if ($siblings.length === 1 && targetClass !== "ordering") {
      $siblings.find(".delete-question").hide();
      $(`.create-question main .${targetClass} footer .shuffle`).hide();
    }

    numberingTheOrderingQuestions(".create-question .ordering ul li", ".choice-number");

    // Recalculate the partial points after deleting
    const fullPointsInput = $(`.create-question .${targetClass} header input[type='number']`);
    const partialPointsInput = $(`.create-question .${targetClass} .question-choices input[type='number']`);
    sumAllPartial(partialPointsInput, fullPointsInput);

    $(`.questions-points-criteria .${targetClass} select`).triggerHandler("change");
  }

  // Allow Drag & Drop in create question
  $(".question-choices").sortable({
    handle: ".choice-drag",
    update: numberingTheOrderingQuestions.bind(this, ".create-question .ordering ul li", ".choice-number"),
  });

  $(".question-choices").disableSelection();

  // Question type criteria select
  $(".questions-type-criteria select").on("change", function () {
    const val = $(this).val();
    const $createQuestion = $(".create-question");

    const $questionTextPassage = $(".create-question main .text-passage");
    const $questionTextPassageMultiDropDown = $(".create-question main .text-passage-multi-dropdown");
    const $editingWizard = $(".create-question main .editing-wizard");

    const $saveBtn = $(".create-question .save-question");

    $saveBtn.removeClass("pointer-none");
    $questionTextPassage.css("display", "block");
    $questionTextPassageMultiDropDown.css("display", "none");
    $editingWizard.css("display", "none");

    $createQuestion.find("main .questions-settings").children().hide().removeClass("selected");
    $createQuestion.find(val).fadeIn().addClass("selected");
    if (val.includes("multi-drop-down")) {
      $questionTextPassage.css("display", "none");
      $questionTextPassageMultiDropDown.css("display", "block");
    } else if (val.includes("fill-in-the-blanks")) {
      $questionTextPassage.css("display", "none");
      $editingWizard.css("display", "block");
      $saveBtn.addClass("pointer-none");
    }
  });

  // Type of true & false
  $(".create-question .true-false .true-false-type select").niceSelect({
    icons: { button: "ui-icon-blank", position: { collision: "flip" } },
  });
  $(".create-question .true-false .true-false-type select").on("change", function () {
    $(".create-question .true-false ul li").removeClass("error"); // remove error class if exists
    const val = $(this).val();
    const trueInput = $(".create-question .true-false ul li:first-of-type input[type='text']");
    const falseInput = $(".create-question .true-false ul li:last-of-type input[type='text']");
    trueInput.attr("disabled", "disabled");
    falseInput.attr("disabled", "disabled");
    trueInput.val("").removeAttr("placeholder").parent().removeClass("correct").removeClass("like");
    falseInput.val("").removeAttr("placeholder").parent().removeClass("wrong").removeClass("dislike");
    if (val == "Use True & False") {
      trueInput.val("True");
      falseInput.val("False");
    } else if (val == "Use icons") {
      trueInput.val("").parent().addClass("correct");
      falseInput.val("").parent().addClass("wrong");
    } else if (val == "use like icons") {
      trueInput.val("").parent().addClass("like");
      falseInput.val("").parent().addClass("dislike");
    } else {
      trueInput.removeAttr("disabled");
      falseInput.removeAttr("disabled");
      trueInput.attr("placeholder", "Enter Option 1");
      falseInput.attr("placeholder", "Enter Option 2");
    }
  });

  // Multi True / False correct & wrong functionality
  $(".create-question .multi-true-false .question-status").on("click", multiTrueFalseStatus);
  function multiTrueFalseStatus() {
    if ($(this).children("i").length) {
      $(this).html("&#10003;");
      $(this).attr("data-status", "correct");
    } else if ($(this).attr("data-status") === "correct") {
      $(this).html("&#215;");
      $(this).attr("data-status", "wrong");
    } else {
      $(this).html("&#10003;");
      $(this).attr("data-status", "correct");
    }
  }

  // Question points criteria select
  $(".questions-points-criteria select").on("change", question_points_criteria);
  function question_points_criteria(e) {
    e.stopPropagation();
    const targetClass = $(".create-question main div.selected").attr("class").split(" ")[0];
    const val = $(this).val();
    const fullPointsInput = $(`.create-question .${targetClass} header input[type='number'].full-points`);
    const partialPointsInput = $(`.create-question .${targetClass} .question-choices input[type='number']`);
    const partialPointInHeader = $(`.create-question .${targetClass} header input[type='number'].partial-points`);
    if (val === "Points") {
      fullPointsInput.fadeIn();
      fullPointsInput.removeAttr("disabled");
      partialPointsInput.fadeOut();
      partialPointInHeader.fadeOut();
    } else if (val === "Partial Points") {
      if (partialPointsInput.length) {
        fullPointsInput.fadeIn();
        fullPointsInput.attr("disabled", "disabled");
        partialPointsInput.fadeIn();
        sumAllPartial(partialPointsInput, fullPointsInput);
        partialPointsInput.on("input", sumAllPartial.bind(this, partialPointsInput, fullPointsInput));
      } else {
        fullPointsInput.fadeIn();
        fullPointsInput.attr("disabled", "disabled");
        partialPointInHeader.fadeIn();
        if ($(".text-passage-multi-dropdown .dropdown-img").length)
          partialPointInHeader.on(
            "input",
            countSumAll.bind(
              this,
              fullPointsInput,
              partialPointInHeader,
              $(".text-passage-multi-dropdown .dropdown-img")
            )
          );
        else if ($(".editing-wizard .question-details .blank").length)
          partialPointInHeader.on(
            "input",
            countSumAll.bind(this, fullPointsInput, partialPointInHeader, $(".editing-wizard .question-details .blank"))
          );
        partialPointInHeader.trigger("input");
      }
    } else {
      fullPointsInput.fadeOut();
      partialPointsInput.fadeOut();
      partialPointInHeader.fadeOut();
    }
  }

  function sumAllPartial(partialPointsInput, fullPointsInput) {
    let sum = 0;
    partialPointsInput.each(function (index, element) {
      sum += parseFloat($(element).val());
    });
    fullPointsInput.val(sum);
  }
  function countSumAll(fullPointsInput, partialPointsVal, box) {
    const dropdownBox = $(".text-passage-multi-dropdown .dropdown-img");
    const blankBox = $(".editing-wizard .question-details .blank");
    let len = null;
    if (dropdownBox.length) len = dropdownBox.length;
    else if (blankBox.length) len = blankBox.length;
    const val = parseFloat(partialPointsVal.val(), 10);
    fullPointsInput.val(val * len);
  }

  // Numbering the order options
  numberingTheOrderingQuestions(".create-question .ordering ul li", ".choice-number");

  // validate data when save changes or close modal
  function validateCreateQuestion() {
    // Validate the choice text
    const $selectedSection = $(".create-question div.selected");
    const selectedClass = $selectedSection.attr("class").split(" ")[0];
    let questionContentValid = true;

    const questionContent = $(".ql-editor p").text().trim();
    if (questionContent === "") {
      questionContentValid = false;
      $(".text-passage-editor").css("border-color", "#f00");
    } else {
      questionContentValid = true;
      $(".text-passage-editor").css("border-color", "#ccc");
    }

    if (selectedClass === "true-false") {
      const typeOfText = $(".create-question .true-false footer select").val();
      const input = $(".create-question .true-false input[type='text']");
      if (typeOfText === "Custom Style") {
        input.each(function (index, element) {
          if ($(element).val().trim() === "") {
            $(element).parent().addClass("error");
          } else {
            $(element).parent().removeClass("error");
          }
        });
      }
    } else {
      let textAreaValid = true;
      let statusValid = true;
      $(`.create-question .${selectedClass} ul li textarea`).each(function (index, element) {
        if ($(element).val().trim() === "") {
          textAreaValid = false;
          $(element).parent().addClass("error");
        }
      });
      if (textAreaValid) {
        $(`.create-question .${selectedClass} ul li`).removeClass("error");
      }

      if ($(`.create-question .${selectedClass} ul li .custom-checkbox input:checked`).length === 0) {
        $(`.create-question .${selectedClass} .error-selected-message`).show();
      } else {
        $(`.create-question .${selectedClass} .error-selected-message`).hide();
      }

      if (selectedClass === "multi-true-false") {
        $(`.create-question .${selectedClass} ul li .question-status`).each(function (index, element) {
          if ($(element).children("i").length) statusValid = false;
        });

        if (statusValid) {
          $(`.create-question .${selectedClass} .error-selected-message`).hide();
        } else {
          $(`.create-question .${selectedClass} .error-selected-message`).show();
        }
      }
    }

    // Validate the choice points
    const pointsType = $(".create-question main header select").val();

    if (pointsType === "Points" || pointsType === "Partial Points") {
      const pointValue = parseFloat($(`.create-question .${selectedClass} header input[type='number']`).val(), 10);
      if (pointValue % 0.5 !== 0 || pointValue <= 0) {
        $(`.create-question .${selectedClass} header input[type='number']`).addClass("error");
      } else {
        $(`.create-question .${selectedClass} header input[type='number']`).removeClass("error");
      }
    }
  }
  $(".create-question header > div #save-question").on("click", validateCreateQuestion);

  // Remove red border from ql-editor
  $(".ql-editor").on("input", function () {
    if ($(this).children("p").text().trim() !== "") {
      $(".text-passage-editor").css("border-color", "#ccc");
    }
  });

  // Chack in every backspace if any multi dropdown has been deleted or not
  $(".ql-editor").on("keydown", function (e) {
    const code = e.which || e.keyCode;
    if (code === 8) {
      const ids = [];
      const numberOfMultiDropdown = $(".multi-dropdown-choices");
      $(".text-passage-multi-dropdown .dropdown-img").each(function (index, element) {
        ids.push($(element).data("id"));
      });

      if (ids.length < numberOfMultiDropdown.length) {
        const diff = numberOfMultiDropdown.filter((x) => !ids.includes($(numberOfMultiDropdown[x]).attr("id")));
        diff.remove();
      }
    }
  });
  $(".ql-editor").bind("paste", function (e) {
    setTimeout(createNewDropdownSettings, 100);
  });
  $(".ql-editor").bind("copy", function (e) {
    if (e.target.tagName === "IMG") {
      var dummyContent = "";
      var dummy = $("<input>").val(dummyContent).appendTo("body").select();
      document.execCommand("copy");
    }
  });
  $(".ql-editor").bind("cut", function (e) {
    if (e.target.tagName === "IMG") $(`#${e.target.dataset.id}`).removeClass("selected");
  });

  // Validate the partial points
  $(".create-question main ul li input[type='number']").on("blur", validatePartialPointsInput);
  function validatePartialPointsInput() {
    const val = parseFloat($(this).val(), 10);
    if (val % 0.5 !== 0 || val < 0) {
      $(this).addClass("error");
    } else {
      $(this).removeClass("error");
    }
  }

  // event on input of the total number of points to remove the error class from the validation
  $(".create-question main header input[type='number']").on("input", function () {
    const val = parseFloat($(this).val(), 10);
    if (val % 5 === 0 || val > 0) {
      $(this).removeClass("error");
    }
  });

  // add new options section for each new dropdown in text passage
  $(".create-question .text-passage-multi-dropdown .ql-multiDropDown").on("click", createNewDropdownSettings);
  function createNewDropdownSettings() {
    // $(getLastItem($(".create-question .text-passage-multi-dropdown .dropdown-img")))
    let id = null;
    const ids = [];
    $(".text-passage-multi-dropdown .dropdown-img").each(function (index, element) {
      ids.push($(element).data("id"));
    });
    ids.forEach((el) => {
      if ($(`#${el}`).length === 0) id = el;
    });

    if (!id) {
      $(".text-passage-multi-dropdown .dropdown-img").each(function (index, element) {
        var events = $._data(element, "events");
        if (!events) {
          id = $(element).data("id");
          $(`.text-passage-multi-dropdown .dropdown-img[data-id=${id}]`).on("click", handleClickOnDropdownImg);
        }
      });
      return null;
    }

    const el = `
      <div class="multi-dropdown-choices" id=${id}>
        <p class="c-orange ml-2">Add choices and select the correct one</p>
        <span class="error-selected-message">One choice must be selected</span>
        <ul class="question-choices ui-sortable">
          <li>
            <label for="choice_correct_${id}_1"> </label>
            <div class="custom-checkbox">
              <div class="form-check">
                <input class="form-control" type="radio" id="choice_correct_${id}_1" name="choice_correct_${id}">
                <label for="choice_correct_${id}_1"></label>
              </div>
            </div>
            <textarea rows="1" name="choice_name_${id}_1" id="choice_name_${id}_1" placeholder="Type a Choice here" maxlength="50"></textarea>
            <i class="far fa-trash-alt delete-question"></i>
          </li>
          <li class="selected">
            <label for="choice_correct_${id}_2"></label>
            <div class="custom-checkbox">
              <div class="form-check">
                <input class="form-control" type="radio" id="choice_correct_${id}_2" name="choice_correct_${id}" checked="checked">
                <label for="choice_correct_${id}_2"></label>
              </div>
            </div>
            <textarea rows="1" name="choice_name_${id}_2" id="choice_name_${id}_2" placeholder="Type a Choice here" maxlength="50"></textarea>
            <i class="far fa-trash-alt delete-question"></i>
          </li>
          <li>
            <label for="choice_correct_${id}_3"></label>
            <div class="custom-checkbox">
              <div class="form-check">
                <input class="form-control" type="radio" id="choice_correct_${id}_3" name="choice_correct_${id}">
                <label for="choice_correct_${id}_3"></label>
              </div>
            </div>
            <textarea rows="1" name="choice_name_${id}_3" id="choice_name_${id}_3" placeholder="Type a Choice here" maxlength="50"></textarea>
            <i class="far fa-trash-alt delete-question"></i>
          </li>
        </ul>
      </div>
    `;

    $(".create-question main .multi-drop-down header").after(el);

    $(`.text-passage-multi-dropdown .dropdown-img[data-id=${id}]`).on("click", handleClickOnDropdownImg);
    function handleClickOnDropdownImg() {
      const oldId = $(".create-question main .multi-drop-down .multi-dropdown-choices.selected").attr("id");
      if (oldId) {
        const dropdownImg = $(`.text-passage-multi-dropdown .dropdown-img[data-id=${oldId}]`);
        if (validateMultiDropdownOptions(oldId))
          dropdownImg
            .attr("src", "img/text-passage/dropdown-valid.png")
            .attr("data-src", "img/text-passage/dropdown-valid.png");
        else
          dropdownImg
            .attr("src", "img/text-passage/dropdown-invalid.png")
            .attr("data-src", "img/text-passage/ropdown-invalid.png");
      }

      if (validateMultiDropdownOptions(id)) $(this).attr("src", "img/text-passage/dropdown-valid-active.png");
      else $(this).attr("src", "img/text-passage/dropdown-invalid-active.png");
      // $(this).attr("src", "img/text-passage/dropdown-invalid-active.png");
      $(".create-question main .multi-drop-down .multi-dropdown-choices").removeClass("selected");
      $("#" + $(this).data("id")).addClass("selected");
      $(".multi-drop-down .questions-points-criteria select").triggerHandler("change");
    }

    $("#" + id + " li textarea").on("input", textareaInput);
    $("#" + id + " li textarea").on("blur", textareaBlur);
    $("#" + id + " li textarea").on("focus", textareaFocus);
    $("#" + id + " li textarea").on("keypress", addNewTextArea);
    $("#" + id + " li .delete-question").on("click", deleteQuestions);

    $("#" + id + " li input[type='radio']").on("change", addSelectedClassRadio);

    $("#" + id + " li .custom-checkbox input[type='radio']").on(
      "change",
      removeErrorMessageWhenSelectingOptionInMultiDropdown
    );

    $(`.create-question .multi-drop-down header input[type='number'].partial-points`).trigger("input");
  }

  function validateMultiDropdownOptions(id) {
    let textAreaValid = true;
    let selectOptionValid = true;
    $(`#${id} ul li textarea`).each(function (index, element) {
      if ($(element).val().trim() === "") {
        textAreaValid = false;
        $(element).parent().addClass("error");
      }
    });
    if (textAreaValid) {
      $(`#${id} ul li`).removeClass("error");
    }

    if ($(`#${id} .custom-checkbox input[type="radio"]:checked`).length === 0) {
      selectOptionValid = false;
      $(`#${id} .error-selected-message`).show();
    }

    if (textAreaValid && selectOptionValid) {
      $(`.text-passage-multi-dropdown .dropdown-img[data-id = ${id}]`).attr(
        "src",
        "img/text-passage/dropdown-valid.png"
      );
    }
    return textAreaValid && selectOptionValid;
  }

  // Remove error message when selecting option in multi dropdown
  function removeErrorMessageWhenSelectingOptionInMultiDropdown() {
    if ($(".multi-dropdown-choices.selected .custom-checkbox input[type='radio']:checked").length !== 0) {
      $(".multi-dropdown-choices.selected .error-selected-message").hide();
    }
  }

  // upload files setting validation
  $(".file-upload .files-settings .option input[type='number']").on("blur", validateUploadFilesNumberInput);
  function validateUploadFilesNumberInput() {
    const val = $(this).val();

    if (val === "" || isFloat(parseFloat(val, 10)) || val <= 0) {
      $(this).addClass("error");
    } else {
      $(this).removeClass("error");
    }
  }

  // Fill in the blanks editing wizard
  $(".add-fill-in-the-blanks .next-btn").on("click", function () {
    const $modal = $(".add-fill-in-the-blanks");
    const questionText = $modal.find(".text-passage-editor").text();
    const questionHTML = $modal.find(".text-passage-editor .ql-editor").html();

    // hide all error message
    $modal.find(".error .error-message").hide();
    // check the question text
    if (questionText === "") {
      $modal.find(".add-question-text-and-one-blank").css("display", "inline-block");
    } else if (questionText.search(/[a-z]|[A-Z]|[1-9]/g) === -1) {
      $modal.find(".add-question-text").css("display", "inline-block");
    } else if (!questionText.includes("____")) {
      $modal.find(".add-one-blank").css("display", "inline-block");
    } else {
      // There is no errors 🎉
      $modal.find(".step-1").hide();
      $modal.find(".step-2").show();

      let counter = 0;

      $modal.find(".step-2 .question-text").html(
        questionHTML.replace(/_{4,}/g, function (p1, p2, p3, p4) {
          counter++;
          return `<span class="blank">${counter}</span>`;
        })
      );
    }
  });
  $(".add-fill-in-the-blanks .back-btn").on("click", function () {
    const $modal = $(".add-fill-in-the-blanks");
    $modal.find(".step-2").hide();
    $modal.find(".step-1").show();
  });
  $(".add-fill-in-the-blanks .save-btn").on("click", function () {
    const questionHTML = $(".add-fill-in-the-blanks .step-2 .question-text").html();
    $(".editing-wizard .question-details").html(questionHTML);
    $(".create-question .fill-in-the-blanks header").css("display", "flex");
    $(".create-question .fill-in-the-blanks header input[type='number'].partial-points").trigger("input");
    $(".create-question .save-question").removeClass("pointer-none");
  });
});

/*
 * Question's Attachments
 *
 */

$(function () {
  // The Upload functionality for upload image modal
  $(".quiz-custom-file-input input[type='file']").on("change", function () {
    $(this).siblings(".file-chosen").text(this.files[0].name);
  });

  // Select functionality in add pdf modal
  $(".add-pdf ul li").on("click", function () {
    $(".add-pdf ul li").removeClass("selected");
    $(this).addClass("selected");
  });

  // Select functionality in add text passage modal
  $(".add-text-passage ul li").on("click", function () {
    $(".add-text-passage ul li").removeClass("selected");
    $(this).addClass("selected");
  });

  // Select functionality in add audio clip modal
  $(".upload-audio .choose-exist ul li").on("click", function () {
    $(this).parents("ul").children("li").removeClass("selected");
    $(this).addClass("selected");
  });

  // Select functionality in add image modal
  $(".add-image ul li").on("click", function () {
    $(this).toggleClass("selected");
  });

  // Delete in #question-attachments
  $("#question-attachments .resources ul li .delete-resource").on("click", deleteResourcesFunctionality);
  function deleteResourcesFunctionality() {
    const type = $(this).parent().parent().parent().parent().data("type");
    $(this).parent().parent().parent().parent().remove();
    if (type === "pdf") {
      $("#question-attachments .btns .pdf-btn").css("display", "block");
    } else if (type === "text") {
      $("#question-attachments .btns .text-btn").css("display", "block");
    } else if (type === "video") {
      $("#question-attachments .btns .youtube-btn").css("display", "block");
    } else if (type === "audio") {
      $("#question-attachments .btns .audio-btn").css("display", "block");
    } else if (type === "link") {
      $("#question-attachments .btns .link-btn").css("display", "block");
    }
  }
  // Create new resource
  $(".edit-exam-page ~ .add-pdf .btn-success").on("click", function () {
    // PDF
    const el = `
        <li class="resource" data-type="pdf">
          <div class="resource-icon"><i class="far fa-file-pdf" style="color: red"></i></div>
          <div class="resource-details">
            <h6 class="resource-name" data-toggle="modal" data-target="#preview-files-pdf" data-type="pdf" data-url="img/no-friends.png">File name File name File name File name File name File name</h6>
            <div class="resource-menu options dropdown"><i class="fas fa-ellipsis-v open-options" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
              <ul class="custom-button-dropdown dropdown-menu dropdown-menu-left">
                <li class="delete-resource"><i class="fas fa-trash-alt"></i>Delete</li>
              </ul>
            </div>
          </div>
        </li>
      `;

    $("#question-attachments .resources > ul").append(el);
    $("#add-pdf").modal("toggle");
    $("#question-attachments .btns button.pdf-btn").css("display", "none");

    $("#question-attachments .resources ul li:last-of-type .delete-resource").on("click", deleteResourcesFunctionality);
  });

  $(".edit-exam-page ~ .add-text-passage .btn-success").on("click", function () {
    // TEXT
    const el = `
        <li class="resource" data-type="text">
          <div class="resource-icon"><i class="far fa-file-alt c-blue"></i></div>
          <div class="resource-details">
            <h6 class="resource-name" data-toggle="modal" data-target="#preview-files-text">Text Passage Title Text Passage Title Text Passage Title Text Passage Title</h6>
            <div class="resource-menu options dropdown"><i class="fas fa-ellipsis-v open-options" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
              <ul class="custom-button-dropdown dropdown-menu dropdown-menu-left">
                <li class="delete-resource"><i class="fas fa-trash-alt"></i>Delete</li>
              </ul>
            </div>
          </div>
        </li>
      `;

    $("#question-attachments .resources > ul").append(el);
    $("#add-text-passage").modal("toggle");
    $("#question-attachments .btns button.text-btn").css("display", "none");

    $("#question-attachments .resources ul li:last-of-type .delete-resource").on("click", deleteResourcesFunctionality);
  });

  $(".edit-exam-page ~ #add-youtube .btn-success").on("click", function () {
    // VIDEO
    const el = `
        <li class="resource" data-type="video">
          <div class="resource-icon"><i class="fab fa-youtube" style="color: red;"></i></div>
          <div class="resource-details"><a class="resource-name" data-toggle="modal" data-target="#preview-video">Video Title Video Title Video Title Video Title Video Title</a>
            <div class="resource-menu options dropdown"><i class="fas fa-ellipsis-v open-options" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
              <ul class="custom-button-dropdown dropdown-menu dropdown-menu-left">
                <li class="delete-resource"><i class="fas fa-trash-alt"></i>Delete</li>
              </ul>
            </div>
          </div>
        </li>
      `;

    $("#question-attachments .resources > ul").append(el);
    $("#add-youtube").modal("toggle");
    $("#question-attachments .btns button.youtube-btn").css("display", "none");

    $("#question-attachments .resources ul li:last-of-type .delete-resource").on("click", deleteResourcesFunctionality);
  });

  $(".edit-exam-page ~ #upload-audio .btn-success").on("click", function () {
    // AUDIO
    const el = `
        <li class="resource" data-type="audio">
          <div class="resource-icon"><i class="fas fa-headphones c-orange"></i></div>
          <div class="resource-details"><a class="resource-name">Audio Clip Name Audio Clip Name Audio Clip Name Audio Clip Name</a>
            <div class="resource-menu options dropdown"><i class="fas fa-ellipsis-v open-options" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
              <ul class="custom-button-dropdown dropdown-menu dropdown-menu-left">
                <li class="delete-resource"><i class="fas fa-trash-alt"></i>Delete</li>
              </ul>
            </div>
          </div>
        </li>
      `;

    $("#question-attachments .resources > ul").append(el);
    $("#upload-audio").modal("toggle");
    $("#question-attachments .btns button.audio-btn").css("display", "none");

    $("#question-attachments .resources ul li:last-of-type .delete-resource").on("click", deleteResourcesFunctionality);
  });

  $(".edit-exam-page ~ #add-link .btn-success").on("click", function () {
    // LINK
    const el = `
        <li class="resource" data-type="link">
          <div class="resource-icon"><i class="fas fa-link c-purple"></i></div>
          <div class="resource-details"><a class="resource-name" href="https://www.example.com/blabla" target="_blank">Link Title Link Title Link Title Link Title Link Title Link Title</a>
            <div class="resource-menu options dropdown"><i class="fas fa-ellipsis-v open-options" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
              <ul class="custom-button-dropdown dropdown-menu dropdown-menu-left">
                <li class="delete-resource"><i class="fas fa-trash-alt"></i>Delete</li>
              </ul>
            </div>
          </div>
        </li>
      `;

    $("#question-attachments .resources > ul").append(el);
    $("#add-link").modal("toggle");
    $("#question-attachments .btns button.link-btn").css("display", "none");

    $("#question-attachments .resources ul li:last-of-type .delete-resource").on("click", deleteResourcesFunctionality);
  });
});
