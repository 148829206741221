jQuery(function ($) {
  // Signup Actions
  $(".next-area").on("click", function () {
    $(".first-step-content").hide();
    $(".second-step-content").fadeIn();
    $(".step-two").addClass("active");
  });
  $(".previous-area").on("click", function () {
    $(".second-step-content").hide();
    $(".first-step-content").fadeIn();
    $(".step-two").removeClass("active");
  });
});
