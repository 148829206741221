/*global jQuery, window */

jQuery(function ($) {
  "use strict";

  if ($(window).width() > 767) {
    $('[data-toggle="tooltip"]').tooltip();
  }
  // Fix for adding 2 data-toggle attr so I made data-tooltip attr to enable tooltip too :)
  $('[data-tooltip="tooltip"]').tooltip();

  // functionality of data-href
  $("[data-href]").on("click", function () {
    window.location.href = $(this).data("href");
  });

  // force only numbers with numbers-only class
  $(".numbers-only").forceNumeric();

  // Date Picker
  $(".date-picker").datepicker();

  // Required Data Attr. Functionality
  dataRequireFunc();

  // Moving Placeholder Effects
  $('.placeholder-up input[type="text"]').on("focusout", function () {
    if ($(this).val() != "") {
      $(this).parent().addClass("has-data");
    } else {
      $(this).parent().removeClass("has-data");
    }
  });

  // Auto Focus On Element
  $(".modal").on("shown.bs.modal", function () {
    $(".auto-focus").focus();
  });

  // Smooth Scroll To Element
  $(".smoothscroll").on("click", function (event) {
    event.preventDefault();
    $("html, body").animate(
      {
        scrollTop: $($(this).data("scroll")).offset().top,
      },
      1000
    );
  });

  // Scroll To Top
  var scrollToTop = $(".scroll-to-top");
  $(window).on("scroll", function () {
    if ($(window).scrollTop() >= 500) {
      if (!scrollToTop.is(":visible")) {
        scrollToTop.fadeIn(300);
      }
    } else {
      if (scrollToTop.is(":visible")) {
        scrollToTop.fadeOut(300);
      }
    }
  });
  $(".scroll-to-top span").on("click", function () {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      1000
    );
  });

  // Intel Telephone Input
  $(".phone-number").intlTelInput({
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js",
  });

  // Only Numbers and Alpha Allowed
  $(".numbers-and-alpha").on("keypress", function (e) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  });

  // Validate Input in Sign Up Form
  $(".validate-form .mandatory").bind("input", function () {
    if (isFilled($(this).parents(".validate-form").find(".mandatory"))) {
      $(this).parents(".validate-form").find(".validate-btn").removeClass("pointer-none");
    } else {
      $(this).parents(".validate-form").find(".validate-btn").addClass("pointer-none");
    }
  });

  function isFilled(elements) {
    var isFilledInput = true;
    elements.each(function () {
      if (
        ($(this).val() !== null && $(this).val().trim() === "" && !$(this).is("div")) ||
        $(this).find("option:selected").val() == 0 ||
        ($(this).is(":not(:checked)") && $(this).attr("type") === "checkbox") ||
        ($(this).find("input[type='radio']").length > 0 && $(this).find("input[type='radio']:checked").length == 0)
      ) {
        isFilledInput = false;
      }
    });
    return isFilledInput;
  }

  // Textarea With Counter
  $(".textarea-counter textarea").on("input", function () {
    var textCounter = $(this).parent().find(".count-chars"),
      textMaxLength = parseInt($(this).attr("maxlength"));
    textCounter.text(textMaxLength - parseInt($(this).val().length));
    if (textCounter.text() == 0) {
      textCounter.addClass("reach-zero");
    } else {
      textCounter.removeClass("reach-zero");
    }
  });

  // Groups & Classes
  $('.groups li input[type="checkbox"]').on("change", function (e) {
    e.stopPropagation();
    if ($(this).is(":checked")) {
      $(this).closest(".groups li").addClass("checked");
    } else {
      $(this).closest(".groups li").removeClass("checked");
    }
  });
  $(".update-group-modal .groups li").on("click", function (e) {
    e.stopPropagation();
    $(this).toggleClass("checked");
    if ($(this).hasClass("checked")) {
      $(this).find("input[type='checkbox']").attr("checked", true);
    } else {
      $(this).find("input[type='checkbox']").attr("checked", false);
    }
  });

  // Select Dependencies
  $(".chain select").on("change", function () {
    var closeDependencies = $(this).closest(".dependencies");
    if ($(this).find("option:selected").val() !== "0") {
      closeDependencies.find(".depend").removeClass("not-selectable");
      closeDependencies.find(".depend select").attr("disabled", false);
    } else {
      closeDependencies.find(".depend").addClass("not-selectable");
      closeDependencies.find(".depend select").attr("disabled", true);
      closeDependencies.find(".depend select").prop("selectedIndex", 0);
    }
  });

  // Handle Active Depend On Filled Fields
  var inputOne = false,
    selectOne = false,
    selectTwo = false;
  $(".dependencies .text-one").on("input", function () {
    if ($(this).val() !== "") {
      inputOne = true;
    } else {
      inputOne = false;
    }
    checkFillStatus();
  });

  $(".dependencies .select-one").on("change", function () {
    if ($(this).closest(".dependencies").find(".text-one").val() !== "") {
      inputOne = true;
    } else {
      inputOne = false;
    }
    if ($(this).val() !== "0") {
      selectOne = true;
    } else {
      selectOne = false;
    }
    checkFillStatus();
  });
  $(".dependencies .select-two").on("change", function () {
    if ($(this).val() !== "0") {
      selectTwo = true;
    } else {
      selectTwo = false;
    }
    checkFillStatus();
  });
  function checkFillStatus() {
    if (inputOne === true && selectOne === true && selectTwo === true) {
      $(".dependencies .fill").attr("disabled", false);
    } else {
      $(".dependencies .fill").attr("disabled", true);
    }
  }

  // Sorting
  $(".the-items").sortable({
    connectWith: "ul",
    delay: 150,
    cursor: "move",
    handle: ".the-icon",
  });

  // Open & Close functionality for settings menu
  $("[data-setting-menu]").on("click", function () {
    openSettingMenu($(this).data("setting-menu"));
  });

  $(".settings-menu-close").on("click", function (e) {
    e.preventDefault();
    var flag = true;
    $(".settings-tab form [required='required']").each(function (index, element) {
      if ($(element).val() === "") {
        $(element).addClass("input-danger");
        $(element).siblings(".error-message").css("display", "inline-block");
        $(".input-danger").on("input", function () {
          $(this).removeClass("input-danger");
          $(this).siblings(".error-message").hide();
        });
        flag = false;
      }
    });
    if (flag) {
      $(".settings-menu .bg-dark").hide();
      $(".settings-menu .settings-content").css("right", "-700px");
      $("body").removeClass("overflow-hidden");
    }
  });

  // Make the textarea expand when text added
  $(".settings-menu textarea").on("input", function () {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
  });

  $(".settings-menu .settings-content").on("click", function () {
    setTimeout(() => {
      $(".settings-menu textarea").trigger("input");
    }, 0);
  });

  // disable enter in name textarea
  $(".enter-diable").on("keydown", function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
    }
  });

  // Functionality for showing more option if some options are enabled
  $(".quiz-checked-show").on("change", function () {
    // if ($(this).is(":checked")) {
    //   if ($(this).data("show")) {
    //     $($(this).data("show")).fadeIn(100);
    //     if ($(this).data("hide")) $($(this).data("hide")).hide();
    //   } else {
    //     $($(this).data("hide")).hide();
    //     if ($(this).data("show")) $($(this).data("show")).fadeIn(100);
    //   }
    // } else {
    //   $($(this).data("show")).fadeOut();
    //   if ($(this).data("hide")) {
    //     $($(this).data("hide")).fadeIn();
    //   }
    // }
    if ($(this).is(":checked")) {
      $($(this).data("hide")).hide();
      $($(this).data("show")).fadeIn(100);
    } else {
      $($(this).data("hide")).fadeIn(100);
      $($(this).data("show")).hide();
    }
  });

  // Fixing pinchzoomer in bootstrap modals
  $(document).on("shown.bs.modal", ".pinchzoomermodel", function () {
    //give it a little delay
    $("body").css("overflow", "hidden");
    setTimeout(function () {
      for (let i = 0; i < PinchZoomer.length; i++) {
        const pz = PinchZoomer.get(i);
        pz && pz.resetElem();
      }
    }, 0.1);
  });

  $(document).on("hidden.bs.modal", ".pinchzoomermodel", function () {
    $("body").css("overflow", "auto");
  });

  // Dropdown details Functionality
  $("[data-dropdown-item]").on("click", function () {
    const dropdownItem = $(this).data("dropdown-item");
    if ($(this).hasClass("fa-chevron-down")) {
      $(this.parentNode.parentNode.parentNode)
        .children("li")
        .each(function (index, element) {
          $(element).children(dropdownItem).slideUp();
          $(element).find("[data-dropdown-item]").removeClass("fa-chevron-up");
          $(element).find("[data-dropdown-item]").addClass("fa-chevron-down");
        });
      $(this).removeClass("fa-chevron-down");
      $(this).addClass("fa-chevron-up");
      $(this.parentNode.parentNode).children(dropdownItem).slideDown();
    } else {
      $(this).removeClass("fa-chevron-up");
      $(this).addClass("fa-chevron-down");
      $(this.parentNode.parentNode).children(dropdownItem).slideUp();
    }
  });

  // show details in finished assignments
  $(".the-items li").click(function () {
    $(this).toggleClass("active");
    $(this).siblings().removeClass("active");
  });

  // Stop youtube videos on closing modals
  $(document).on("hidden.bs.modal", function () {
    $("iframe").each(function () {
      const src = $(this).attr("src");
      $(this).attr("src", src);
    });
  });
});
