jQuery(function ($) {
  /**
   * checkall-bar functionalities
   */

  // Check Any Checkbox Element
  $(".checkall-bar-content input[type='checkbox']").on("click", function () {
    checkPageContainer();
    countAllChecks();
  });

  // Cancel Button
  $(".checkall-bar .close-button span").on("click", function () {
    $(".checkall-bar-content").find("input[type='checkbox']").prop("checked", false);
    countAllChecks();
  });

  // Check All Checkboxes Elements
  $(".checkall-bar .check-box input[type='checkbox']").on("change", function () {
    if ($(this).is(":checked")) {
      $(".checkall-bar-content.active").find("input[type='checkbox']").prop("checked", true);
      countAllChecks();
    } else {
      $(".checkall-bar-content.active").find("input[type='checkbox']").prop("checked", false);
      countAllChecks();
    }
  });
});
