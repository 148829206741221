jQuery(function ($) {
  /**
   * sidebar functionalities
   */

  // Check If Sidebar Exists Or No
  checkPageContainer();

  // Toggle Sidebar
  $(".toggle-sidebar").on("click", function () {
    $(".page-container").toggleClass("small-sidebar");
    checkPageContainer();
  });

  // Toggle Mobile Sidebar
  $(".toggle-mobile-sidebar").on("click", function (e) {
    e.stopPropagation();
    $(".sidebar").toggleClass("hidden-mobile");
    $(".sidebar-overlay").toggle();
  });

  // Toggle Child Links in Sidebar
  $(".open-menu").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass("opened").next(".child-links").slideToggle();
    $(this).parent().siblings("li").find(".opened").removeClass("opened").next("ul").slideToggle();
  });
});
