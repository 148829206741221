$(function () {
  // flag functionality
  $(".run-exam-page .exam .attachments .flag input[type='checkbox']").on("change", function () {
    if ($(this).is(":checked")) $(".questions-board  .questions li.selected").addClass("flaged");
    else $(".questions-board  .questions li.selected").removeClass("flaged");
  });
  $(".run-exam-page-syle2 .exam .attachments .flag input[type='checkbox']").on("change", function () {
    const questionNum = $(this).parents(".question").attr("id").replace("question-", "");
    const $element = $($(".questions-board  .questions li")[questionNum - 1]);
    if ($(this).is(":checked")) $element.addClass("flaged");
    else $element.removeClass("flaged");
  });

  // select correct answer functionality
  $(".exam .exam-content .multi-choices li").on("click", function () {
    $(this).toggleClass("selected");
    const $checkbox = $(this).find("input[type='checkbox']");
    $checkbox.is(":checked") ? $checkbox.prop("checked", false) : $checkbox.prop("checked", true);
  });
  $(".exam .exam-content .single-choice li").on("click", function () {
    $(this).toggleClass("selected").siblings().removeClass("selected");
    const $checkbox = $(this).find("input[type='radio']");
    $checkbox.is(":checked") ? $checkbox.prop("checked", false) : $checkbox.prop("checked", true);
  });
  $(".exam .exam-content .question-choices li input").on("click", function (e) {
    e.preventDefault();
    $(this).parents("li").trigger("click");
  });

  // mobile bars functionality
  $(".exam .mobile-bars").on("click", function () {
    $(".questions-board").addClass("questions-board-mobile");
    $(".questions-board .content").animate(
      {
        right: "0",
      },
      250
    );
  });

  // close questions-board when click outside or select any question
  $(".questions-board .bg-black, .questions-board .questions li").on("click", function () {
    $(".questions-board .content").animate(
      {
        right: "-400",
      },
      250,
      function () {
        $(".questions-board").removeClass("questions-board-mobile");
      }
    );
  });

  // Scroll top by data-scroll-top attr
  $(".run-exam-page-syle2 .questions-board .questions  li[data-scroll-top]").on("click", function () {
    const $this = $(this);
    $(".exam-content").scrollTo($this.data("scroll-top"));
    // Listen for scroll events

    $(".exam-content").on("scroll", function (event) {
      clearTimeout(isScrolling);
      var isScrolling = setTimeout(function () {
        console.log("000");
        $this.addClass("selected").siblings().removeClass("selected");
        clearTimeout(isScrolling);
      }, 100);
    });
  });
});
