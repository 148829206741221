/*global jQuery, window */

jQuery(function ($) {
  "use strict";

  // Stop Propagation On Profile Drop
  $(".profile-drop").on("click", function (e) {
    e.stopPropagation();
  });

  // Focus On Add Friends First Input
  $(".add-person-modal").on("shown.bs.modal", function () {
    $(".first-input").focus();
  });

  $(".alpha-only").on("keydown", function (event) {
    var arr = [8, 9, 16, 17, 20, 35, 36, 37, 38, 39, 40, 45, 46];
    for (var i = 65; i <= 90; i++) {
      arr.push(i);
    }
    if (jQuery.inArray(event.which, arr) === -1) {
      event.preventDefault();
    }
  });

  // Ascending Table For Demo Only
  $(".assignments-table .fa-sort-asc").on("click", function () {
    $(this).toggleClass("fa-sort-asc fa-sort-desc");
  });

  // Exercises Count Percentage
  $(".excer-percentage").each(function () {
    let $valOne = $(this).find(".line-one span:eq(0)").text(),
      $valTwo = $(this).find(".line-one span:eq(1)").text(),
      $valPerc = ($valOne * 100) / $valTwo;
    $(this)
      .find(".line-two")
      .text($valPerc.toFixed() + "%");
    if ($valPerc >= 50) {
      $(this).find(".line-two").addClass("over50").append("<span>Passed</span>");
    } else {
      $(this).find(".line-two").addClass("below50").append("<span>Failed</span>");
    }
  });

  // Dashboard Animated Progress Bar
  $(".the-progress span").each(function () {
    $(this).animate(
      {
        width: $(this).parent().parent().find(".perc").data("perc") + "%",
      },
      2000
    );
  });

  // Prevent Propagation On Profile Dropdown
  $(".profile-box .dropdown-menu p, .profile-box .dropdown-menu h3").on("click", function (e) {
    e.stopPropagation();
  });

  // Toggle From To Time Filter In Quiz Searches
  $('.from-to-time input[type="radio"]').on("click", function () {
    if ($(this).hasClass("range-time")) {
      $(".from-to").fadeIn();
    } else {
      $(".from-to").fadeOut();
    }
  });

  // Testing Sweet Alert
  $(".submit-verify-code").on("click", function () {
    swal({
      title: "Phone number changed",
      text: "The new phone number is Bahrain +9765656565.",
      icon: "success",
    });
  });

  // Testing Sweet Alert
  $(".show-delete-account").on("click", function () {
    swal({
      title: "Account Deleting",
      text: "Your account has been deleted",
      icon: "error",
      dangerMode: true,
    });
  });
});
