jQuery(function ($) {
  // Libraries
  var choosenLib = "",
    choosenColor = "#0070c0";
  $(".lib-box").on("click", function () {
    choosenLib = $(this).attr("id");
  });
  $(".update-colors li").on("click", function () {
    choosenColor = $(this).data("color");
    $(this).addClass("active").siblings().removeClass("active");
  });
  $("#update-colors .update").on("click", function () {
    $("#" + choosenLib)
      .find(".head")
      .css("borderTopColor", choosenColor);
    $("#" + choosenLib)
      .find(".head .name, .capacity")
      .css("color", choosenColor);
    $("#" + choosenLib)
      .find(".foot")
      .css("backgroundColor", choosenColor);
    $("#" + choosenLib)
      .find(".open-colors")
      .data("color", choosenColor);
  });
  $(".open-colors").on("click", function () {
    $(".update-colors li[data-color='" + $(this).data("color") + "']").addClass("active");
  });
  $("#update-colors").on("hidden.bs.modal", function () {
    $(".update-colors li").removeClass("active");
  });
  // Grade Libraries
  $(".upload-choose h4").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
    $(".show-content").hide();
    $($(this).data("show")).show();
  });
  $(".gallery-content img").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
  });
  var currentLib = "";
  var currentImageSrc = "";
  $(".open-options").on("click", function () {
    var theId = $(this).closest(".lib-box").attr("id");
    currentLib = theId;
    currentImageSrc = $("#" + currentLib)
      .find(".img-box img")
      .attr("src");
    $(".gallery-content .images img").each(function () {
      if ($(this).attr("src") === currentImageSrc) {
        $(this).addClass("active").siblings().removeClass("active");
      }
    });
  });
  // Update Image
  $(".update-lib-button").on("click", function () {
    $("#" + currentLib)
      .find(".img-box img")
      .attr("src", $(".images .active").attr("src"));
  });
  // Sort Library Contents
  $(".contents").sortable({
    connectWith: "div",
    delay: 150,
    cursor: "move",
    handle: ".row-reorder",
  });
  // Library Contents
  $(".content-box .row-name").on("click", function () {
    if ($(this).data("type") === "video") {
      var theUrl = $(this).data("url");
      $(".preview-area *").remove();
      $(".preview-area").append("<iframe src='https://www.youtube.com/embed/" + theUrl + "' frameborder='0'></iframe>");
    } else if ($(this).data("type") === "image") {
      var theUrl = $(this).data("url");
      $(".preview-area *").remove();
      $(".preview-area").append("<img src='" + theUrl + "'>");
    } else if ($(this).data("type") === "pdf") {
      var theUrl = $(this).data("url");
      $(".preview-area *").remove();
      $(".preview-area").append("<iframe src='" + theUrl + "'></iframe>");
    }
  });
  $(".content-box .row-name a").on("click", function (e) {
    e.stopPropagation();
  });
});
