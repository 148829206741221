/*global jQuery, window */

jQuery(function ($) {
  "use strict";

  // Headroom Header
  var myElement = document.querySelector(".upper-bar");
  if (myElement){
    var headroom = new Headroom(myElement);
    headroom.init();
  }

  // Manage Active Links On Submenu
  $(".sidebar-links li").each(function () {
    if ($(this).find(".child-links .active").length > 0) {
      $(this).addClass("active");
      $(this).find(".open-menu").addClass("opened");
      $(this).find(".child-links").show();
    }
  });

  // Toggle Info in Assignments Page Mobile
  $(".show-info").on("click", function () {
    $(this).toggleClass("hidden-info");
  });
  $(".show-info-container").on("click", function (e) {
    e.stopPropagation();
  });

  $(".sidebar-overlay").on("click", function () {
    if ($(this).is(":visible")) {
      $(".sidebar").toggleClass("hidden-mobile");
      $(this).toggle();
    }
  });

  $(".phone-number").intlTelInput({
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js",
  });

  // Stop Propagation in Mailbox
  $(".mailbox-table .delete-message, .notifications-table .delete-message").on("click", function (event) {
    event.stopPropagation();
  });

  // Global Confirm Message
  $(".global-reject-message").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "This Message Will Come From Backend.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Done.", {
          icon: "success",
        });
      } else {
        swal("Not Done");
      }
    });
  });

  // Demo Only
  $('.join-requests input[type="checkbox"]').each(function () {
    $('.join-requests input[type="checkbox"]').on("change", function () {
      if ($('.join-requests input[type="checkbox"]').is(":checked")) {
        $(".reject-request, .accept-request").removeClass("disabled");
      } else {
        $(".reject-request, .accept-request").addClass("disabled");
      }
    });
  });

  // Choose Friends
  $(".select-friends").on("click", function () {
    $(this).next(".choose-friends").slideToggle();
  });

  // Select Checkbox
  $(".choose-friends li").on("click", function () {
    $(this).find("input").trigger("click");
  });
});

// Force Input Numeric
jQuery.fn.forceNumeric = function () {
  return this.each(function () {
    $(this).keydown(function (e) {
      var key = e.which || e.keyCode;
      if (
        (!e.shiftKey &&
          !e.altKey &&
          !e.ctrlKey &&
          // numbers
          key >= 48 &&
          key <= 57) ||
        // Numeric keypad
        (key >= 96 && key <= 105) ||
        // comma, period and minus, . on keypad
        key == 190 ||
        key == 188 ||
        key == 109 ||
        key == 110 ||
        // Backspace and Tab and Enter
        key == 8 ||
        key == 9 ||
        key == 13 ||
        // Home and End
        key == 35 ||
        key == 36 ||
        // left and right arrows
        key == 37 ||
        key == 39 ||
        // Del and Ins
        key == 46 ||
        key == 45
      )
        return true;
      return false;
    });
  });
};
