jQuery(function ($) {
  $(".proj-g-page .groups-settings .groups-list li").on("click", function () {
    $(this).siblings().removeClass("selected");
    $(this).addClass("selected");
    $(".proj-g-page .groups-settings .go-btn").removeClass("pointer-none");
  });

  $(".proj-g-page .page-header .marks .current")
    .forceNumeric()
    .on("keydown", function (e) {
      const key = e.which || e.keyCode;
      if (key === 13 || key === 109) return false;
    })
    .on("input", function () {
      const val = parseInt($(this).text(), 10);
      const total = parseInt($(this).siblings(".total").text(), 10);
      const $percentage = $(".proj-g-page .page-header .percentage .num");
      $percentage.text(((val / total) * 100).toPrecision(2));
    });
});
