jQuery(function ($) {
  // Scrolling functionality and select the question which in the current viewport
  $(".run-exam-page-syle2 .question").waypoint({
    handler: function (direction) {
      const id = this.element.id;
      const questionNumber = id.replace("question-", "");
      $($(".run-exam-page-syle2 .questions-board .questions li")[questionNumber - 1])
        .addClass("selected")
        .siblings()
        .removeClass("selected");
    },
    context: ".exam-content",
    offset: -62,
  });
  $(".run-exam-page-syle2 .question").waypoint({
    handler: function (direction) {
      const id = this.element.id;
      const questionNumber = id.replace("question-", "");
      $($(".run-exam-page-syle2 .questions-board .questions li")[questionNumber - 1])
        .addClass("selected")
        .siblings()
        .removeClass("selected");
    },
    context: ".exam-content",
    offset: "bottom-in-view",
  });
});
