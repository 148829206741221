const $audioPlayer = $(".audio-player");

if ($audioPlayer.length) {
  const sound = new Howl({
    src: [$audioPlayer.data("src")],
    onload: function () {
      $audioPlayer.find(".time .total").text(formatTime(sound.duration()));
    },
    onplay: function () {
      requestAnimationFrame(step);
      $audioPlayer.find(".play").hide();
      $audioPlayer.find(".pause").css("display", "flex");
    },
    onend: function () {
      $audioPlayer.find(".pause").hide();
      $audioPlayer.find(".play").css("display", "flex");
    },
    onpause: function () {
      $audioPlayer.find(".pause").hide();
      $audioPlayer.find(".play").css("display", "flex");
    },
    onstop: function () {
      $audioPlayer.find(".pause").hide();
      $audioPlayer.find(".play").css("display", "flex");
    },
  });

  $audioPlayer.find(".play").on("click", function () {
    sound.play();
  });

  $audioPlayer.find(".pause").on("click", function () {
    sound.pause();
  });

  $audioPlayer.find(".sound").on("click", function () {
    const currentVolume = sound.volume();
    if (currentVolume) {
      sound.volume(0);
      $(this).find("i").removeClass("fa-volume-up").addClass("fa-volume-mute");
    } else {
      sound.volume(1);
      $(this).find("i").removeClass("fa-volume-mute").addClass("fa-volume-up");
    }
  });

  $audioPlayer.find(".progress").on("click touchend touchmove touchstart", function (e) {
    var rect = this.getBoundingClientRect();
    const per = (e.clientX - rect.left) / $(this).width();
    sound.seek(sound.duration() * per);
    requestAnimationFrame(step);
  });

  function formatTime(secs) {
    var minutes = Math.floor(secs / 60) || 0;
    var seconds = Math.floor(secs - minutes * 60) || 0;

    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  function step() {
    // Determine our current seek position.
    const seek = sound.seek() || 0;

    // Upadte the time & the progress bar
    $audioPlayer.find(".time .current").text(formatTime(seek));
    $audioPlayer.find(".progress .progress-bar").css("width", ((seek / sound.duration()) * 100 || 0) + "%");

    // If the sound is still playing, continue stepping.
    if (sound.playing()) {
      requestAnimationFrame(step);
    }
  }

  $(".modal").on("hidden.bs.modal", function (e) {
    sound.pause();
  });
}
