jQuery(function ($) {
  // Custom Dropdown
  $("body").on("click", ".custom-drop .dropdown-menu", function (e) {
    $(this).parent().is(".show") && e.stopPropagation();
  });

  $(".selectall").click(function () {
    if ($(this).is(":checked")) {
      $(".option").prop("checked", true);
      var total = $('input[name="options[]"]:checked').length;
      $(".dropdown-text").html("(" + total + ") Selected");
      $(".select-text").html("Un-Check");
    } else {
      $(".option").prop("checked", false);
      $(".dropdown-text").html("(0) Selected");
      $(".select-text").html("Check");
    }
  });

  $(".custom-drop input[type='checkbox'].justone").change(function () {
    var a = $("input[type='checkbox'].justone");
    if (a.length == a.filter(":checked").length) {
      $(".selectall").prop("checked", true);
      $(".select-text").html("Un-Check");
    } else {
      $(".selectall").prop("checked", false);
      $(".select-text").html("Check");
    }
    var total = $('input[name="options[]"]:checked').length;
    $(".dropdown-text").html("(" + total + ") Selected");
  });

  // Enable datepicker from jQueryUI
  $(".customDateInput input").each(function (index, element) {
    $(element).datepicker({ dateFormat: "dd-mm-yy" });
    $(element)
      .siblings("span")
      .on("click", function () {
        $(element).datepicker("show");
      });
  });

  $(".customTimeInput input").each(function (index, element) {
    $(element).timepicker({
      timeFormat: "hh:mm TT",
    });
    $(element)
      .siblings("span")
      .on("click", function () {
        $(element).timepicker("show");
      });
  });

  $(".range input").on("change", function (e) {
    const startDate = $(this).parent().parent().find("input[name='date-start']");
    const endDate = $(this).parent().parent().find("input[name='date-end']");
    if (!startDate.val()) {
      startDate.val(endDate.val());
    }
    if (startDate.val() > endDate.val()) {
      if (e.target.name == "date-start") {
        endDate.val(startDate.val());
      } else {
        startDate.val(endDate.val());
      }
    }
  });

  // enable bootstrap datepicker
  if ($(".picked-date").length != 0) {
    // Date Picker
    $(".picked-date").bootstrapMaterialDatePicker();
  }

  // main search
  $(".main-search i").click(function () {
    $(this).closest(".main-search").addClass("active").find("input").focus();
  });
  $(".main-search input").blur(function () {
    $(this).closest(".main-search").removeClass("active");
  });

  // Custom Dropdown Selectbox
  $(".custom-selectbox .current-item").on("click", function () {
    const $this = $(this);
    $this.siblings(".dropdown-items").toggle();
    $(window).on("click", function (e) {
      if ($(e.target).parents(".custom-selectbox").length === 0) {
        $this.siblings(".dropdown-items").hide();
        $(window).unbind("click");
      }
    });
    if ($this.css("display") === "none") {
      $(window).unbind("click");
    }
  });
  $(".dropdown-items .item").on("click", function () {
    $(this).siblings().removeClass("selected");
    $(this).addClass("selected");
    $(".custom-selectbox .current-item .item").html($(this).html());
    $(this).parents(".dropdown-items").hide();
  });
});
