$(function () {
  // Add Selected Class to the items when select the checkbox
  $(".my-works-page .tabs-content li .quiz-checkbox input").on("change", function () {
    if ($(this).is(":checked")) {
      $(this).parents("li").addClass("selected");
    } else {
      $(this).parents("li").removeClass("selected");
    }
  });

  // Enable select in sharing sidebar
  selectFunctionalityForStudentsInJoinBox(
    "#share .users-list .join-box",
    "#share .users-list .selected",
    "#share .selected-num",
    null,
    null
  );

  // validation and enable share btn in sharing sidebar
  $("#share .users-list .join-box").on("click", function () {
    const len = parseInt($("#share .selected-num").text());
    const $btn = $("#share .controllers button");

    if (len > 0) {
      $btn.removeClass("pointer-none");
    } else {
      $btn.addClass("pointer-none");
    }
  });

  // enabling select in homework assigning
  selectFunctionalityForStudentsInJoinBox(
    ".assign-new-homework .step-2 .group-list li",
    ".assign-new-homework .step-2 .group-list ul .selected",
    ".assign-new-homework .step-2 .group-list .select-bar .select-num",
    ".assign-new-homework .step-2 .group-list .select-bar .select-total",
    ".assign-new-homework .step-2 .group-list .select-bar input[type='checkbox']"
  );
  selectFunctionalityForStudentsInJoinBox(
    ".assign-new-homework .step-2 .students-list .users-list .join-box",
    ".assign-new-homework .step-2 .students-list .users-list .selected",
    ".assign-new-homework .step-2 .students-list .select-bar .select-num",
    ".assign-new-homework .step-2 .students-list .select-bar .select-total",
    ".assign-new-homework .step-2 .students-list .select-bar input[type='checkbox']"
  );
  selectFunctionalityForStudentsInJoinBox(
    ".assign-exercise .step-2 .students-list .users-list .join-box",
    ".assign-exercise .step-2 .students-list .users-list .selected",
    ".assign-exercise .step-2 .students-list .select-bar .select-num",
    ".assign-exercise .step-2 .students-list .select-bar .select-total",
    ".assign-exercise .step-2 .students-list .select-bar input[type='checkbox']"
  );
});
