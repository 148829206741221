$(function () {
  // The editor plugin for the text passage modals
  if ($(".text-passage-editor").length) {
    var Counter = function (quill, options) {
      this.quill = quill;
      this.options = options;
      this.container = document.querySelector(options.container);
      quill.on("text-change", this.update.bind(this));
      this.update(); // Account for initial contents
    };

    Counter.prototype.calculate = function () {
      var text = this.quill.getText();
      if (this.options.unit === "word") {
        text = text.trim();
        // Splitting empty text returns a non-empty array
        return text.length > 0 ? text.split(/\s+/).length : 0;
      } else {
        return text.length;
      }
    };

    Counter.prototype.update = function () {
      var length = this.calculate();
      var label = this.options.unit;
      if (length !== 1) {
        label += "s";
      }
      if (this.container) {
        this.container.innerHTML = length;
      }
    };

    Quill.register("modules/counter", Counter);

    $(".text-passage-editor").each(function (index, element) {
      var icons = Quill.import("ui/icons");
      icons["clean"] = "<span>Clear Format</span>";
      let quill;
      if ($(element).parent().hasClass("text-passage-multi-dropdown")) {
        icons["multiDropDown"] = '<img src="img/text-passage/insert-dd-icon.png" />';

        var InlineBlot = Quill.import("formats/image");
        class dropdownImage extends InlineBlot {
          static create(data) {
            const node = super.create(data);
            node.setAttribute("data-src", data.src);
            node.setAttribute("src", data.src);
            node.setAttribute("data-id", data.id);
            return node;
          }
          static value(domNode) {
            let { src, id } = domNode.dataset;
            // if ($(`.dropdown-img[data-id=${id}]`).length) {
            //   id = new Date().getTime() + "" + Math.round(Math.random()*10000);
            // }
            return { src, id };
          }
        }
        dropdownImage.blotName = "dropdownImage";
        dropdownImage.className = "dropdown-img";
        dropdownImage.tagName = "img";
        Quill.register({ "formats/dropdownImage": dropdownImage });

        quill = new Quill(element, {
          modules: {
            toolbar: {
              container: [
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline"],
                [{ color: [] }, { background: [] }],
                [{ script: "super" }, { script: "sub" }],
                ["formula"],
                [{ direction: "rtl" }],

                [{ align: [] }],

                ["clean"],
                ["multiDropDown"],
              ],
              handlers: {
                multiDropDown: () => {
                  var range = quill.getSelection();
                  if (range) {
                    quill.insertEmbed(
                      range.index,
                      "dropdownImage",
                      {
                        src: "img/text-passage/dropdown-invalid.png",
                        id: new Date().getTime() + "" + Math.round(Math.random() * 10000),
                      },
                      "user"
                    );
                    quill.insertText(range.index + 1, "\u00a0", "user");
                  }
                },
              },
            },
            counter: {
              container: ".text-passage-counter span",
              unit: "word",
            },
          },
          placeholder: $(element).data("placeholder"),
          theme: "snow", // or 'bubble'
        });
      } else {
        quill = new Quill($(element).get(0), {
          modules: {
            toolbar: {
              container: [
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline"],
                [{ color: [] }, { background: [] }],
                [{ script: "super" }, { script: "sub" }],
                ["formula"],
                [{ direction: "rtl" }],

                [{ align: [] }],

                ["clean"],
              ],
            },
            counter: {
              container: ".text-passage-counter span",
              unit: "word",
            },
          },
          placeholder: $(element).data("placeholder"),
          theme: "snow", // or 'bubble'
        });
      }

      quill.setText("");

      $(".ql-clean").parent().addClass("ql-format-right");
    });
  }
});
