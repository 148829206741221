jQuery(function ($) {
  // Join Search Field
  $(".join-search input, .section-bar--search input").on("focus", function () {
    $(this).parent().addClass("active");
    $(this).addClass("focused");
  });
  $(".join-search input, .section-bar--search input").on("blur", function () {
    if ($(this).val() === "") {
      $(this).parent().removeClass("active");
      $(this).removeClass("focused");
    }
  });
});
