$(function () {
  // add selected class on select a row in mailbox page
  $(".mailbox-list input[type='checkbox'], .notifications-list input[type='checkbox']").on("change", function () {
    if ($(this).is(":checked")) {
      $(this).parents("li").addClass("selected");
    } else {
      $(this).parents("li").removeClass("selected");
    }
  });

  // Make class "take-conent-height" to make the content have the height of its content
  $(".open-message").on("shown.bs.modal", function () {
    if ($(this).find(".message-text-content")[0].scrollHeight > 150) {
      $("textarea.message-text-content:read-only").css("height", "150px");
      $("textarea.message-text-content:read-only").css("overflow-y", "auto");
    } else {
      $("textarea.message-text-content:read-only").css("height", $(this).find(".message-text-content")[0].scrollHeight);
    }
  });
});
