function getLastItem(items) {
  return items[items.length - 1];
}

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

function numberingTheOrderingQuestions(items, numClass) {
  const questions = $(items);
  let num = 1;
  questions.each((index, element) => {
    $(element)
      .children(numClass)
      .text(num++);
  });
}

// Selecting functionality for students in join-box class
function selectFunctionalityForStudentsInJoinBox(
  boxSelector,
  selectedSelector,
  currentSelectedCounter,
  totalSelector,
  allCheckboxSelector
) {
  let len = $(selectedSelector).length;
  let total = totalSelector && $(totalSelector).text();

  // Select one by one
  $(boxSelector).on("click", function (e) {
    $(this).toggleClass("selected");
    len = $(selectedSelector).length;
    $(currentSelectedCounter).text(len);

    if (len == total) {
      $(allCheckboxSelector).prop("checked", true);
    } else {
      $(allCheckboxSelector).prop("checked", false);
    }
  });

  // Select All
  $(allCheckboxSelector).on("click", function () {
    if ($(this).is(":checked")) {
      $(boxSelector).addClass("selected");
    } else {
      $(boxSelector).removeClass("selected");
    }
    len = $(selectedSelector).length;
    $(currentSelectedCounter).text(len);
  });

  $(boxSelector)
    .children(".img-container")
    .children(".quiz-checkbox")
    .on("click", function (e) {
      e.preventDefault();
    });
}

// Open setting menu funtion
function openSettingMenu(id) {
  $(id + " .bg-dark").show();
  $(id + " .settings-content").css("right", "0");
  $("body").addClass("overflow-hidden");
  $(".settings-menu textarea").trigger("input");
}

// validate requires function
function dataRequireFunc() {
  $("*:attrStartsWith(data-require)").each(function (index, element) {
    if ($(element).data("require-length")) {
      $.each($(element).data("require-length").split("&&"), function (i, e) {
        if (e.indexOf("input") !== -1) {
          $(e.substr(0, e.indexOf(":"))).on("change", checkAllValid);
        } else {
          $(e.substr(0, e.indexOf(".selected"))).on("click", checkAllValid);
        }
      });
    }

    if ($(element).data("require")) {
      $.each($(element).data("require").split("&&"), function (i, e) {
        if ($(e).is("[type='text']")) {
          if ($(e).is("[readonly]")) {
            $(e).on("change", checkAllValid);
          } else {
            $(e).on("input", checkAllValid);
          }
        } else {
          $(e).on("change", checkAllValid);
        }
      });
    }

    function checkAllValid() {
      let allvalid = true;

      if ($(element).data("require")) {
        $.each($(element).data("require").split("&&"), function (i, e) {
          if ($(e).is("[readonly]")) {
            if ($(e).val() == "") {
              allvalid = false;
            }
          } else {
            if (!$(e).is(":valid")) {
              allvalid = false;
            }
          }
        });
      }

      if (allvalid) {
        if ($(element).data("require-length")) {
          $.each($(element).data("require-length").split("&&"), function (i, e) {
            if ($(e).length == 0) {
              $(element).attr("disabled", "disabled");
            } else {
              $(element).removeAttr("disabled");
            }
          });
        } else {
          $(element).removeAttr("disabled");
        }
      } else {
        $(element).attr("disabled", "disabled");
      }
    }
  });
}

// Check If Sidebar Exists Or No
function checkPageContainer() {
  var pagePadding;
  $(".page-container").each(function () {
    if ($(this).hasClass("small-sidebar")) {
      pagePadding = 80;
    } else {
      pagePadding = parseInt($(this).css("paddingLeft").replace("px", ""), 10);
    }
  });
  $(".checkall-bar").css("left", pagePadding);
}

// Check All Bar Fucntion
function countAllChecks() {
  var elementsCount = $(".checkall-bar-content.active input[type='checkbox']").length;
  var checkedCounts = 0;
  var chkElement = $(".checkall-bar");
  $(".checkall-bar-content input[type='checkbox']").each(function () {
    if ($(this).is(":checked")) {
      checkedCounts += 1;
      $(this).closest("tr").addClass("selected");
      $(this).closest(".friend-box").addClass("selected");
      $(this).closest(".assistant-box").addClass("selected");
      $(this).closest(".join-box").addClass("selected");
      $(this).closest(".class-box").addClass("selected");
    } else {
      $(this).closest("tr").removeClass("selected");
      $(this).closest(".friend-box").removeClass("selected");
      $(this).closest(".assistant-box").removeClass("selected");
      $(this).closest(".join-box").removeClass("selected");
      $(this).closest(".class-box").removeClass("selected");
    }
    $(".checkall-bar .check-text span").text(checkedCounts);
    checkedCounts > 0 ? chkElement.addClass("open") : chkElement.removeClass("open");
    if (elementsCount === checkedCounts) {
      $(".checkall-bar .check-box input[type='checkbox']").prop("checked", true);
    } else {
      $(".checkall-bar .check-box input[type='checkbox']").prop("checked", false);
    }
  });
}
