jQuery(function ($) {
  $(".will-delete").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Item Will Be Permenantly Deleted.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("The Item Has Been Deleted.", {
          icon: "success",
        });
      } else {
        swal("The Item Is Not Deleted");
      }
    });
  });

  $(".will-close").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Item Will Be Closed.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  });

  $(".will-archive").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Item Will Be Archived.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willArchive) => {
      if (willArchive) {
        swal("The Item Has Been Archived.", {
          icon: "success",
        });
      } else {
        swal("The Item Is Not Archived");
      }
    });
  });

  $(".will-end").on("click", function () {
    swal({
      title: "Are You Sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willArchive) => {
      if (willArchive) {
        swal("Assignment Has Been Ended.", {
          icon: "success",
        });
      }
    });
  });

  // Ongoind Confirm when select "set as completed"
  $(".ongoing-tab .ongoin-confirm").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Item Will Be Set As Completed",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        swal("The Item Has Been Moved to Completed Tab", {
          icon: "success",
        });
        // $(this).parents(".element").remove();
      } else {
        swal(" The Item Is Not Moved to Completed Tab");
      }
    });
  });

  // enable delete the questions
  $(".edit-exam-page .edit-exam-content ul li .delete").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Item Will Be Permenantly Deleted.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        swal("The Item Has Been Deleted.", {
          icon: "success",
        });
        $(this).parent().parent().remove();
        numberingTheOrderingQuestions(".edit-exam-page .edit-exam-content ul li", ".exam-num");
        numbersOfEditExamHeaderPage();
      } else {
        swal(" The Item Is Not Deleted");
      }
    });
  });

  // show confirmation popup on reject
  $(".my-works-page .shared-tab .reject").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Item Will Be Permenantly Rejected.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        swal("The Item Has Been Rejected.", {
          icon: "success",
        });
      } else {
        swal(" The Item Is Not Rejected");
      }
    });
  });

  // show confirmation popup on archive
  $(".my-works-page .archive").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Item Will Be Archived",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        swal("The Item Has Been Archived.", {
          icon: "success",
        });
      } else {
        swal(" The Item Is Not Archived");
      }
    });
  });

  // show confirmation popup on submit
  $(".run-project-page .students-workspace-tab .project-submit").on("click", function () {
    swal({
      title: "Are You Sure?",
      text: "Submitting your work means you won't be able to modify it any more. Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        swal("Your work has been submitted successfully", {
          icon: "success",
        });
      } else {
        swal("Your work hasn't been submitted");
      }
    });
  });
});
