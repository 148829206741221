//  Creating Custom selector
jQuery.extend(jQuery.expr[":"], {
  attrStartsWith: function (el, _, b) {
    for (var i = 0, atts = el.attributes, n = atts.length; i < n; i++) {
      if (atts[i].nodeName.toLowerCase().indexOf(b[3].toLowerCase()) === 0) {
        return true;
      }
    }

    return false;
  },
});

// To make the tabs get into center after click it :)
$.fn.tabbing = function (options) {
  var opts = { delayTime: 300 };
  options = options || {};
  opts = $.extend(opts, options);
  return this.each(function () {
    $(this).on("click", function (event) {
      event.preventDefault();
      var sum = 0;
      $(this)
        .prevAll()
        .each(function () {
          sum += $(this).outerWidth();
        });
      var dist = sum - ($(this).parent().outerWidth() - $(this).outerWidth()) / 2;
      if (dist < 0) {
        dist = 0;
      }
      $(this).parent().animate(
        {
          scrollLeft: dist,
        },
        opts["delayTime"]
      );
    });
  });
};

// Force Input Numeric
jQuery.fn.forceNumeric = function () {
  return this.each(function () {
    $(this).keydown(function (e) {
      var key = e.which || e.keyCode;
      if (
        (!e.shiftKey &&
          !e.altKey &&
          !e.ctrlKey &&
          // numbers
          key >= 48 &&
          key <= 57) ||
        // Numeric keypad
        (key >= 96 && key <= 105) ||
        // comma, period and minus, . on keypad
        key == 190 ||
        key == 188 ||
        key == 109 ||
        key == 110 ||
        // Backspace and Tab and Enter
        key == 8 ||
        key == 9 ||
        key == 13 ||
        // Home and End
        key == 35 ||
        key == 36 ||
        // left and right arrows
        key == 37 ||
        key == 39 ||
        // Del and Ins
        key == 46 ||
        key == 45
      )
        return true;
      return false;
    });
  });
};
