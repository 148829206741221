$(function () {
  /*
   * Class View Page
   * @Ezzdin
   *
   * */

  // check that there is no fraction & the value is more than 0 in input:number
  $(".divide-wizard input[type='number']").each(function (index, element) {
    $(element).on("input", function () {
      if (
        $(this).val().indexOf(".") !== -1 ||
        $(this).val() <= 0 ||
        $(this).val() > parseInt($(".subgroups-tab .subgroups__number--ungrouped span:first-child").text(), 10)
      ) {
        $(this.parentNode.parentNode).next(".error-message").show();
      } else {
        $(this.parentNode.parentNode).next(".error-message").hide();
      }
    });
  });

  // Show start and end date section when selecting range in completed filter modal
  $(".completed-filter .time input[name='time']").change(function (e) {
    if (e.target.id === "range") {
      $(".completed-filter .range").css("display", "flex").hide().fadeIn();
    } else {
      $(".completed-filter .range").fadeOut();
    }
  });

  // Show start and end date section when selecting range in exercises filter modal
  $(".exercises-filter .time input[name='exercises-filter-time']").change(function (e) {
    if (e.target.id === "exercises-filter-range") {
      $(".exercises-filter .range").css("display", "flex").hide().fadeIn();
    } else {
      $(".exercises-filter .range").fadeOut();
    }
  });

  // Select Rows in Class View Page
  $(".class-view-page .quiz-checkbox input[type='checkbox']").on("change", function () {
    if ($(this).is(":checked")) {
      $(this.parentNode.parentNode.parentNode.parentNode).addClass("selected");
    } else {
      $(this.parentNode.parentNode.parentNode.parentNode).removeClass("selected");
    }
  });

  // Select homework in step 1 of new assign
  $(".new-assign-step-1 li, .new-publish-step-1 li").on("click", function (e) {
    $(".new-assign-step-1 ul li, .new-publish-step-1 li").each(function () {
      $(this).removeClass("selected");
    });
    $(e.target).closest("li").addClass("selected");
    $(".new-assign-step-1 .quiz-modal-button, .new-publish-step-1 .quiz-modal-button").removeAttr("disabled");
  });

  // Selecting student in step 2 of new assign in class view page
  selectFunctionalityForStudentsInJoinBox(
    ".new-assign-step-2 .join-box",
    ".new-assign-step-2 .student-list .selected",
    ".new-assign-step-2 .status .c-orange",
    ".new-assign-step-2 .status .c-blue",
    ".new-assign-step-2 .quiz-checkbox input[type='checkbox']"
  );

  // Select on update class model
  $(".update-class-view li").on("click", function () {
    if (!$(this).children(".group-select").children(".quiz-checkbox").children("input").is(":checked")) {
      $(this).addClass("checked");
      $(this).children(".group-select").children(".quiz-checkbox").children("input").prop("checked", true);
    } else {
      $(this).removeClass("checked");
      $(this).children(".group-select").children(".quiz-checkbox").children("input").prop("checked", false);
    }
  });

  // Add padding to the top when the div get pinned (sticky div)
  const el = document.querySelector(".class-view-page .sticky-div");
  if (el) {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
      { threshold: [1] }
    );
    observer.observe(el);
  }

  // Enable selecting in ungrouped students
  selectFunctionalityForStudentsInJoinBox(
    "#ungrouped-setudents-settings .student-list .join-box",
    "#ungrouped-setudents-settings .student-list .selected",
    "#ungrouped-setudents-settings .select-bar p span.c-orange",
    "#ungrouped-setudents-settings .select-bar p span.c-blue",
    "#ungrouped-setudents-settings .select-bar .quiz-checkbox input[type='checkbox']"
  );

  // Enable selecting in grouped students
  selectFunctionalityForStudentsInJoinBox(
    "#grouped-setudents-settings .student-list .join-box",
    "#grouped-setudents-settings .student-list .selected",
    "#grouped-setudents-settings .select-bar p span.c-orange",
    "#grouped-setudents-settings .select-bar p span.c-blue",
    "#grouped-setudents-settings .select-bar .quiz-checkbox input[type='checkbox']"
  );

  // add the red style to some values
  $(".completed-tab .join-requests > ul > li.join-box").each(function (index, element) {
    const graded = $(element).children(".element-content").children(".element-status").children(".graded");
    if (graded.children("span:first-child").text() != 25) {
      graded.addClass("red");
    }
  });

  // new assign in class view
  $("#new-assign .ok").on("click", function () {
    const val = $("#new-assign .type input[name='type']:checked").val();
    if (val === "homework") {
      openSettingMenu("#assign-new-homework");
    } else if (val === "project") {
      openSettingMenu("#assign-new-project");
    } else if (val === "exam") {
      openSettingMenu("#assign-new-exam");
    }
  });

  // select functionality in step 1 of new assign project type
  $(".assign-new-steps .new-assign-list li").on("click", function () {
    $(this).parent().children("li").removeClass("selected");
    $(this).addClass("selected");
    $(this).parents(".settings-content").find(".validate-btn").removeClass("pointer-none");
  });

  // go to step 2 in assign new project
  $(".assign-new-steps .step-1 .validate-btn").on("click", function () {
    const $parent = $(this).parents(".assign-new-steps");
    $parent.find(".step-1").hide();
    $parent.find(".step-2").fadeIn();
  });

  // Back button in step 2 to go to step 1 again
  $(".assign-new-steps .step-2 .back-btn").on("click", function () {
    const $parent = $(this).parents(".assign-new-steps");
    $parent.find(".step-2").hide();
    $parent.find(".step-1").fadeIn();
  });

  // selecting functionality in step 2 of assign new project
  selectFunctionalityForStudentsInJoinBox(
    ".assign-new-project .step-2 .students-list .users-list .join-box",
    ".assign-new-project .step-2 .students-list .users-list .selected",
    ".assign-new-project .step-2 .students-list .select-bar .select-num",
    ".assign-new-project .step-2 .students-list .select-bar .select-total",
    ".assign-new-project .step-2 .students-list .select-bar input[type='checkbox']"
  );

  selectFunctionalityForStudentsInJoinBox(
    ".assign-new-project .step-2 .group-list li",
    ".assign-new-project .step-2 .group-list ul .selected",
    ".assign-new-project .step-2 .group-list .select-bar .select-num",
    ".assign-new-project .step-2 .group-list .select-bar .select-total",
    ".assign-new-project .step-2 .group-list .select-bar input[type='checkbox']"
  );

  // enable button in step 2 of assign new project
  $(".assign-new-project .step-2, .assign-new-homework .step-2, .assign-exercise .step-2").on(
    "click",
    assignNewProjectValidateStep2
  );
  $(
    ".assign-new-project .step-2 .datetime input, .assign-new-homework .step-2 .datetime input, .assign-exercise .step-2 .datetime input"
  ).on("change", assignNewProjectValidateStep2);

  function assignNewProjectValidateStep2() {
    const $parent = $(this).parents(".assign-new-steps");
    const dateVal = $parent.find(".datetime .customDateInput input").val();
    const timeVal = $parent.find(".datetime .customTimeInput input").val();
    const isGroup = $parent.find(".assign-type input").is(":checked");
    const studentsSelected = $parent.find(".students-list .users-list .selected").length;
    const groupsSelected = $parent.find(".group-list .selected").length;
    const $btn = $parent.find(".controllers .validate-btn");
    if (dateVal && timeVal) {
      if ((isGroup && groupsSelected) || (!isGroup && studentsSelected)) {
        $btn.removeClass("pointer-none");
        return;
      }
    }
    $btn.addClass("pointer-none");
  }

  // show classes after selecting the grade
  $(".change-class .controllers select").on("change", function () {
    if ($(this).val()) {
      $(".change-class main ul").fadeIn();
    }
  });

  // selecting functionality of classes list
  $(".change-class main ul li").on("click", function () {
    $(this).parent().children("li").removeClass("selected");
    $(this).addClass("selected");
    $(".change-class .controllers .validate-btn").removeClass("pointer-none");
  });

  // Calculate the students before showing step 2
  $(".assign-new-exam .step-1 .validate-btn").on("click", countAllNewProject);

  // functionality of Re-invite button
  $(".assign-new-exam .step-2 .reinvite").on("click", function () {
    $(this).replaceWith("<span class='status pending'>Pending</span>");
  });

  // functionality of Exclude button
  $(".assign-new-exam .step-2 .exclude").on("click", function () {
    $(this).text("Invite");
    $(this).removeClass("exclude quiz-model-btn-danger-light");
    $(this).addClass("reinvite quiz-model-btn-primary");
    const $parent = $(this).parents(".student");
    $parent
      .clone()
      .appendTo(".assign-new-exam .step-2 .outside-tab .row")
      .find(".reinvite")
      .on("click", function () {
        $(this).replaceWith("<span class='status pending'>Pending</span>");
      });
    $parent.remove();
    countAllNewProject();
  });

  /**
   * It's to calculate all students in step 2 of assign new exam
   *
   */
  function countAllNewProject() {
    const waitingCounter = $(".assign-new-exam .step-2 .waiting-hall-tab .join-box").length;
    const outsideCounetr = $(".assign-new-exam .step-2 .outside-tab .join-box").length;
    $(".assign-new-exam .step-2 .tabs-switcher .total").text(waitingCounter + outsideCounetr);
    $("#waiting-num").text(waitingCounter);
    $("#outside-num").text(outsideCounetr);
    const $btn = $(".assign-new-exam .step-2 .validate-btn");
    if (waitingCounter > 0) {
      $btn.removeClass("pointer-none");
    } else {
      $btn.addClass("pointer-none");
    }
  }
});
