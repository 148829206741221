$(function () {
  /*
   * Co-Teacher Managing Modal
   *
   */

  // selecting functionality
  $(".co-teacher main li .photo").on("click", function () {
    $(this).parent().toggleClass("selected");
  });

  // Show badge when it's value larger than 1 in Students Tab
  $(".students-tab .join-box .join-buttons .badge").each(function (index, element) {
    if (parseInt($(element).text(), 10) > 1) {
      $(element).show();
    } else {
      $(element).hide();
    }
  });
});
