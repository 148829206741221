jQuery(function ($) {
  /**
   * Tabs Switcher functionalities
   */

  // Tabs Switcher
  $(".tabs-switcher").on("click", "li", function () {
    if (!$(this).hasClass("active")) {
      $(".tabs-content ul li").removeClass("selected");
      $(this).addClass("active").siblings().removeClass("active");
      $($(this).data("content")).addClass("active").siblings().removeClass("active");
    }
    // Disable Selection For Elements Appear in The Upper Bar
    $(".checkall-bar-content").find("input[type='checkbox']").prop("checked", false);
    countAllChecks();
    if ($(".tabs-switcher").offset().top > 400) {
      $("html,body").animate(
        {
          scrollTop: 270,
        },
        50
      );
    }
  });

  // enable tabbing
  $(
    ".class-view-page .tabs-switcher li, .configurations .tabs-switcher li, .classes-and-students .tabs-switcher li, .my-works-page .tabs-switcher li"
  ).tabbing();
});
